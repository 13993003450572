<template>
  <div>
    <a class="btn" @click="toggleShow">Click to Upload Profile</a>
    <img-upload
      field="img"
      @crop-success="cropSuccess"
      @crop-upload-success="cropUploadSuccess"
      @crop-upload-fail="cropUploadFail"
      v-model="show"
      :width="width"
      :height="height"
      :noSquare="true"
      :langType="'en'"
      img-format="png"
    ></img-upload>
    <img :src="value" :class="value ? 'img-upload' : ''" />
  </div>
</template>
<script>
// import "babel-polyfill"; // es6 shim

import imgUpload from "vue-image-crop-upload/upload-2.vue";

export default {
  name: "UploadImage",

  props: {
    width: {
      type: Number,
      default: 200,
    },
    // 剪裁图片的高
    height: {
      type: Number,
      default: 200,
    },

    value: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      show: false,

      imgDataUrl: "", // the datebase64 url of created image
    };
  },
  components: { imgUpload },
  methods: {
    reject() {
      this.rejectFunction();
    },

    accept() {
      this.acceptFunction(this.item);
      this.$emit("input", this.item);
    },

    toggleShow() {
      this.show = !this.show;
    },
    /**
     * crop success
     *
     * [param] imgDataUrl
     * [param] field
     */
    cropSuccess(imgDataUrl, field) {
      // console.log("-------- crop success --------");
      this.imgDataUrl = imgDataUrl;

      this.$emit("input", this.imgDataUrl);
    },
    /**
     * upload success
     *
     * [param] jsonData  server api return data, already json encode
     * [param] field
     */
    cropUploadSuccess(jsonData, field) {
      // console.log("-------- upload success --------");
      // console.log("field: " + field);
    },
    /**
     * upload fail
     *
     * [param] status    server api return error status, like 500
     * [param] field
     */
    cropUploadFail(status, field) {
      console.log("-------- upload fail --------");
      console.log(status);
      console.log("field: " + field);
    },
  },

  computed: {
    sourceImgStyle() {
      return {};
    },
  },

  mounted() {},
};
</script>
<style scoped>
.img-upload {
  margin: auto;
  padding: 3px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  overflow: hidden;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-radius: 100%;

  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: space-around;
  justify-content: space-around;
  align-items: stretch;
}
</style>
